/* header color*/
.ant-table-thead .ant-table-cell {
  background-color: #08979c;
  color: white;
}

/* sort color*/
.ant-table-thead th.ant-table-column-sort {
  background: #08979c;
}

.ant-table-title {
  background-color: #25787a;
  color: white;
  text-align: center;
  font-weight: bold;
}

#background-fix {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

#ant-card-body {
  background-color: #ffffff;
  opacity: 0.9;
  filter: alpha(opacity=60);
}

.ant-descriptions-small .ant-descriptions-row > th {
  padding-bottom: 12px;
  /*background-color: #b5f5ec;*/
}

h1.increase {
  font-size: 100px;
  font-family: "Times New Roman", Times, serif;
  color: #ffffff;
  height: 425px;
  line-height: 425px;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#drop-area {
  border: 2px dashed rgb(180, 175, 209);
  border-radius: 10px;
  width: 100%;
  font-family: sans-serif;
  margin: 10px auto;
  padding: 20px;
}
#drop-area2 {
  border: 2px dashed rgb(180, 175, 209);
  border-radius: 10px;
  font-family: sans-serif;
  margin: 10px auto;
  padding: 20px;
  width: 100%;
}
#drop-area.highlight {
  border-color: purple;
}

/* placeholder date picker*/
.ant-picker-input-placeholder > input {
  color: rgb(4, 23, 187);
}

.text-warp-table {
  display: inline-block;
  line-height: 1.5;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: justify;
}

.pic-rihes-list {
  float: right;
}

li::marker {
  color: rgb(60, 177, 14);
}

@media (max-width: 1000px) {
  .pic-rihes-list {
    float: left;
  }
}
