.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-lang :hover {
  box-shadow: 2px 2px 5px #000000;
 
}
