@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");

.rise {
  font-size: 3rem;
  text-shadow: -0.01em -0.01em 0.01em #000;
  animation: rise 2s ease-in-out 0.5s forwards;
}

@keyframes rise {
  to {
    text-shadow: 0em 0.01em #ff5, 0em 0.02em #ff5, 0em 0.02em 0.03em #ff5,
      -0.01em 0.01em #333, -0.02em 0.02em #333, -0.03em 0.03em #333,
      -0.04em 0.04em #333, -0.01em -0.01em 0.03em #000,
      -0.02em -0.02em 0.03em #000, -0.03em -0.03em 0.03em #000;
    transform: translateY(-0.025em) translateX(0.04em);
  }
}
